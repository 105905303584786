.form-container {
  width: 100%;
  margin-top: 20px;
  margin: 20px;
}

@media screen and (max-width: 1366px) {
  .grid-container {
    display: grid;
    column-gap: 30px;
    grid-template-columns: repeat(2, 1fr);
  }
  .card {
    width: 100%;
  }

}
@media screen and (max-width: 1024px) {
  .grid-container {
    display: grid;
    column-gap: 50px;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 768px) {
  .grid-container {
    display: grid;
    column-gap: 100px;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 480px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .main-container {
    flex: 10;
    margin: 15px;
    width: 100%;

  }
  /* AppsalesReport.js */
  .form-container {
    width: 70%;
  }

}
